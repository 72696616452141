import axios from 'axios'
import { getCookie, setCookie } from "@/utils/cookie";

// 实例化
const request = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_API : '/',
  timeout: 15000, // 10-15s
  headers: {
    // contentType: 'application/json'
  }
})
// 请求拦截器
let loginRedirected = false;
request.interceptors.request.use((config) => {
  if (getCookie("banktoken")) {
    config.headers['Authorization'] = getCookie("banktoken") // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  // config.headers['Content-Type'] = 'application/json'
  return config
},
error => {
  Promise.reject(error)
})

// 响应拦截器
request.interceptors.response.use(async (res) => {
  // res.status 200
  // if (res) {
  //   if (getCookie("token")) {
  //     res.headers.Authorization = getCookie("token")
  //   }
  //   return res;
  // }
  if (res.data.code === 200) {
    return Promise.resolve(res.data)
  } 
  else if (res.data.code === 406) {
    if (!loginRedirected) {
      loginRedirected = true;
      router.push('/login');
      Notification({
        title: '跳转登录',
        message: '登录失效,请重新登录才能继续操作',
        type: 'warning',
        position: 'bottom-right'
      });
      return Promise.reject(res.data);
    }
   
  }
  else {
    return Promise.reject(res.data)
  }
})

export default request