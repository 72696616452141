<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "App",

};
</script>
<style lang="less">
@font-face {
  font-family: "思源黑体 Light";
  font-weight: 300;
  src: url("//at.alicdn.com/wf/webfont/eRJvuoMsZgK8/ibbEhyMNJTf0.woff2") format("woff2"),
    url("//at.alicdn.com/wf/webfont/eRJvuoMsZgK8/qM5FdUhRldHH.woff") format("woff");
  font-display: swap;
}

html {

  font-family: '思源黑体 Normal', Helvetica, 'Microsoft YaHei', Arial, sans-serif;
}

* {
  margin: 0;
  padding: 0;
}
</style>
