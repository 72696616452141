import Vue from 'vue'
import Vuex from 'vuex'
import { getCookie, setCookie } from "@/utils/cookie";
import { login } from '@/api/login'

import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: getCookie(),
    user: {},
    roles: [],
    // 第一次加载菜单时用到
    loadMenus: false
  },
  getters: {
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_USER: (state, user) => {
      state.user = user
    },
  },
  actions: {
    Login({ commit }, user) {
      return new Promise((resolve, reject) => {
        login(user.loginType, user.userName, user.password, user.verificationCode).then((res) => {
          commit('SET_TOKEN', res.data.token)
          commit('SET_USER', res.data.userInfo)
          console.log(res.data)
          setCookie("banktoken", res.data.token,7)
          resolve()
        }).catch((err) => {
          reject(err)
        })
      })
    },
  },
  modules: {
  },
  plugins: [createPersistedState({
    reducer(val) {
      // 此时，当count发生改变的时候，就会调用此函数，并且val的值为当前state对象，return的值为当前本地存储的value值（本地存储的key值为vuex）
      return {
        user: val.user,
      }
    }

  })]
})
