import Vue from 'vue'
import VueRouter from 'vue-router'
import { getCookie } from "@/utils/cookie";
import { module } from './module/routes'
Vue.use(VueRouter)

let originPush = VueRouter.prototype.push;

VueRouter.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {
    //如果成功 调用原来的push方法  
    originPush.call(this, location, resolve, reject);
  } else {
    originPush.call(this, location, () => { }, () => { });
  }
}

const routes = [
  ...module
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
  if (!getCookie("banktoken")) {

    if (to.path !== '/login') {

      next('/login')
    } else {
     
      next()
    }
  } else {
    next()
  }
})

export default router
