import request from '@/utils/request'
import qs  from 'qs';


export function login(loginType, userName, passWord, verificationCode,) {
    return request({
        url: '/bank-api/auth/login',
        method: 'post',
        data: {
            loginType,
            userName,
            passWord,
            verificationCode,
        }
    })
}
export function getcode(data) {
    return request({
        url: '/bank-api/common/sendMessage',
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data,
    })
}
export function applyuser(orgName,
    orgUserName,
    orgUserPhone,
    remark,) {
    return request({
        url: '/bank-api/common/apply_account',
        method: 'post',
        data: {
            orgName,
            orgUserName,
            orgUserPhone,
            remark,
        }
    })
}