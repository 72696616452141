import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import BaiduMap from 'vue-baidu-map'
import VueRouter from 'vue-router';
import md5 from 'js-md5';
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'


import { setCookie, getCookie, checkCookie, clearCookie } from '@/utils/cookie';
Vue.prototype.$setCookie = setCookie;
Vue.prototype.$getCookie = getCookie;
Vue.prototype.$checkCookie = checkCookie;
Vue.prototype.$clearCookie = clearCookie;
Vue.use(Viewer);

Viewer.setDefaults({
  Options: { "inline": true, "button": true, "navbar": true, "title": true, "toolbar": true, "tooltip": true, "movable": true, "zoomable": true, "rotatable": true, "scalable": true, "transition": true, "fullscreen": true, "keyboard": true, "url": "data-source" }
});
Vue.prototype.$md5 = md5;
Vue.use(VueRouter)
Vue.config.productionTip = false
Vue.use( BaiduMap, { ak : 'OD8UO4GaeZdTPFzA3sncMDXBlqDQAdzT' })
Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
